//
// Variables
//

// Font Family
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

//
// Premium font - Roboto
//

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Medium.ttf");
  src: local("Roboto"), url("../../../fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Medium.ttf");
  src: local("Roboto"), url("../../../fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Medium.ttf");
  src: local("Roboto"), url("../../../fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Medium.ttf");
  src: local("Roboto"), url("../../../fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Medium.ttf");
  src: local("Roboto"), url("../../../fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 700;
}
